import React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import CtaBanner from "../../components/cta-banner";
import Layout from "../../components/layout";
import ServicesHero from "../../components/services/hero";
import { useStaticQuery, graphql } from "gatsby";
import ServiceCard from "../../components/serviceCard";
import { GatsbySeo } from "gatsby-plugin-next-seo";
import { Helmet } from "react-helmet";

const PhotographyTrainingPage = () => {
	const data = useStaticQuery(graphql`
		query {
			heroImg: file(
				relativePath: { eq: "services/training/training-hero.jpg" }
			) {
				childImageSharp {
					gatsbyImageData(
						quality: 100
						placeholder: BLURRED
						formats: [AUTO, WEBP]
					)
				}
			}
			trainingImg: file(relativePath: { eq: "services/training.jpg" }) {
				childImageSharp {
					gatsbyImageData(
						quality: 100
						placeholder: BLURRED
						formats: [AUTO, WEBP]
					)
				}
			}
			socialImg: file(relativePath: { eq: "services/training/social.jpg" }) {
				childImageSharp {
					gatsbyImageData(
						quality: 100
						placeholder: BLURRED
						formats: [AUTO, WEBP]
					)
				}
			}
			site {
				siteMetadata {
					siteUrl
				}
			}
		}
	`);

	const siteUrl = data.site.siteMetadata.siteUrl;

	const breadcrumb = {
		"@context": "http://schema.org",
		"@type": "BreadcrumbList",
		itemListElement: [
			{
				"@type": "ListItem",
				position: 1,
				name: "Home",
				item: `${siteUrl}`,
			},
			{
				"@type": "ListItem",
				position: 2,
				name: "Photography Training",
				item: `${siteUrl}/services/photography-training`,
			},
		],
	};

	return (
		<>
			<Layout>
				<Helmet>
					<script type="application/ld+json">
						{JSON.stringify(breadcrumb)}
					</script>
				</Helmet>

				<GatsbySeo
					title="Professional Photography Training | Sarah Bennett Photography"
					description="We provide expert photography courses for business owners and amateur photographers who want to take professional photos"
					language="en"
					canonical="https://www.sarah-bennett.com/services/photography-training"
					openGraph={{
						url: "https://www.sarah-bennett.com/services/photography-training",
						title:
							"Professional Photography Training  | Sarah Bennett Photography",
						description:
							"We provide expert photography courses for business owners and amateur photographers who want to take professional photos",
						images: [
							{
								url: `${data.heroImg.publicURL}`,
								width: 800,
								height: 600,
								alt: "Professional photography training",
							},
						],
					}}
				/>
				<ServicesHero
					img={data.heroImg.childImageSharp.gatsbyImageData}
					headline="Photography Training"
					subheadline="All the tools you need to build a successful photography business"
					showBtn={false}
				/>
				<section>
					<Container>
						<Row>
							<Col className="h-100">
								<ServiceCard
									name="Social Media Photography"
									img={data.socialImg.childImageSharp.gatsbyImageData}
									description="For businesses owners wanting to improve their basic photography skills, I run The Social Media Photography Course. This is a brand new self-paced online course. For business owners wanting to begin producing their own social media content. It covers photography for LinkedIn, Facebook, Instagram and Twitter and explains how to create flat lay photography and content for your ongoing marketing."
									// url="/services/social-media-photography-course"
								/>
							</Col>
							<Col className="h-100">
								<ServiceCard
									name="The Business of Photography"
									img={data.trainingImg.childImageSharp.gatsbyImageData}
									description="The Business of Photography Course is for new photographers and any photographer who either wants to launch a new business or rebrand or relaunch their current business. Perhaps you can relate? I understand how scary it can feel to take the leap and become a full time, professional photographer. That's why I created The Business of Photography Course."
									// url="/services/business-of-photography-course"
								/>
							</Col>
						</Row>
					</Container>
				</section>
				{/* <Review/> */}
				<CtaBanner
					headline="Want to speak to me to discuss which course is right for you? Book an appointment now."
					btnText="Let's Talk"
					url="/contact-us"
				/>
			</Layout>
		</>
	);
};

export default PhotographyTrainingPage;
